@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #fbfbfb;
  font-weight: 300;
}

.MuiListSubheader-root ~ .MuiMenuItem-root {
  font-size: 0.85rem;
  padding-left: 32px;
}

a:not(.MuiButtonBase-root),
a:not(.MuiButtonBase-root):hover,
a:not(.MuiButtonBase-root):visited {
  color: inherit;
}

.fileUpload {
  padding: 16px;
  min-height: 120px;
  margin-bottom: 32px;
}

.fileUpload:focus {
  outline: 0;
}

.fileUpload p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.85rem;
  margin-top: 0;
}

.fileUpload img {
  width: 100px;
  height: auto;
  margin: 0 16px 0 0;
  box-shadow: none;
}

.fileUpload .MuiGrid-grid-xs-4 {
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  align-items: center;
  text-align: left;
}

.mTable-filter-cell {
  background: #fff7e2;
}

.error-boundary {
  max-width: 640px;
  margin: 6rem auto 0;

  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}

.error-boundary__title {
  margin: 0;
  padding: 0;

  font-size: 2.25rem;
}

.error-boundary__explanation {
  margin: 3.5rem 0 0.5rem;
  padding: 0;

  font-size: 1.25rem;
  line-height: 1.2;
}

.error-boundary__details-text {
  margin: 1rem 0;
  padding: 0;

  font-size: 90%;
}

.error-boundary__details {
  margin: 1rem 0;
  padding: 1.15rem 1.25rem;

  font-size: 75%;
  font-family: monospace;
  line-height: 1.35;

  background: #efefef;
  border-radius: 1rem;
}

.error-boundary__error {
}

.error-boundary__component-stack {
  padding: 0 1rem;
  white-space: pre-line;
  color: #666;
}

.error-boundary__help-contact {
}

.error-boundary__reset-button-container {
  display: flex;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
}

.error-boundary__reset-button {
  margin: 3rem 0 0;
  padding: 0.75rem 0.9rem;

  background: #cc0000;
  color: #fff;
  font-weight: bold;

  border-radius: 0.5rem;
  border: none;

  cursor: pointer;
}

.error-boundary__reset-button:hover,
.error-boundary__reset-button:focus {
  background: #c76161;
}

