@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #fbfbfb;
  font-weight: 300;
}

.MuiListSubheader-root ~ .MuiMenuItem-root {
  font-size: 0.85rem;
  padding-left: 32px;
}

a:not(.MuiButtonBase-root),
a:not(.MuiButtonBase-root):hover,
a:not(.MuiButtonBase-root):visited {
  color: inherit;
}

.fileUpload {
  padding: 16px;
  min-height: 120px;
  margin-bottom: 32px;
}

.fileUpload:focus {
  outline: 0;
}

.fileUpload p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.85rem;
  margin-top: 0;
}

.fileUpload img {
  width: 100px;
  height: auto;
  margin: 0 16px 0 0;
  box-shadow: none;
}

.fileUpload .MuiGrid-grid-xs-4 {
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  align-items: center;
  text-align: left;
}

.mTable-filter-cell {
  background: #fff7e2;
}
