.error-boundary {
  max-width: 640px;
  margin: 6rem auto 0;

  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}

.error-boundary__title {
  margin: 0;
  padding: 0;

  font-size: 2.25rem;
}

.error-boundary__explanation {
  margin: 3.5rem 0 0.5rem;
  padding: 0;

  font-size: 1.25rem;
  line-height: 1.2;
}

.error-boundary__details-text {
  margin: 1rem 0;
  padding: 0;

  font-size: 90%;
}

.error-boundary__details {
  margin: 1rem 0;
  padding: 1.15rem 1.25rem;

  font-size: 75%;
  font-family: monospace;
  line-height: 1.35;

  background: #efefef;
  border-radius: 1rem;
}

.error-boundary__error {
}

.error-boundary__component-stack {
  padding: 0 1rem;
  white-space: pre-line;
  color: #666;
}

.error-boundary__help-contact {
}

.error-boundary__reset-button-container {
  display: flex;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
}

.error-boundary__reset-button {
  margin: 3rem 0 0;
  padding: 0.75rem 0.9rem;

  background: #cc0000;
  color: #fff;
  font-weight: bold;

  border-radius: 0.5rem;
  border: none;

  cursor: pointer;
}

.error-boundary__reset-button:hover,
.error-boundary__reset-button:focus {
  background: #c76161;
}
